import React, {Component} from 'react';
import './style.less'
import DownOutlined from "../Icon/DownOutlined";

const site = process.env.PUBLIC_URL;
const friendLink = [
    {
        name:'中国电子信息产业集团有限公司',
        link:'https://www.cec.com.cn/'
    },
    {
        name:'中国中电国际信息服务有限公司',
        link:'http://www.cecis.com.cn/'
    },
    {
        name:'中国电子云',
        link:'https://cecloud.com/'
    },
    {
        name:'深圳市桑达无线通讯技术有限公司',
        link:'http://www.sedwt.com.cn'
    },
    {
        name:'深圳桑达电子设备有限公司',
        link:'http://www.sedee.com.cn/'
    },
    {
        name:'捷达国际运输有限公司',
        link:'http://www.cetjd.com/'
    },
    {
        name:'中国电子系统技术有限公司',
        link:'https://www.cestc.cn/'
    },
    {
        name:'中国电子系统工程第二建设有限公司',
        link:'http://www.cese2.com/'
    },
    {
        name:'中国电子系统工程第三建设有限公司',
        link:'http://www.cese3.com/'
    },
    {
        name:'中国电子系统工程第四建设有限公司',
        link:'http://www.cefoc.cn/'
    },
    // {
    //     name:'中电系统建设工程有限公司',
    //     link:'http://cec-cesec.com.cn/'
    // }
]
const categoryArray = [
    {
        code: 'technicalAndService',
        name: '数字与信息服务',
        path: site + '/informationService',
        childrenArray: [
            {
                code: '',
                name: '数字基础设施',
                path: 'https://cecloud.com',
                target: "_blank",
            },
            {
                code: '',
                name: '数据创新',
                path: site + '/informationService/dataInnovation'
            },
            // {
            //     code: '',
            //     name: '数据服务',
            //     path: site + '/informationService/dataGovernanceAndOperations'
            // },
            {
                code: '',
                name: '数字政府与行业数字化',
                path: site + '/informationService/digitalService/cityBrain'
            },

            // {
            //     code: '',
            //     name: 'DigitalConsulting',
            //     path: site + '/informationService/digitalConsulting'
            // }
        ],
    },
    {
        code: 'industrialService',
        name: '产业服务',
        path: site + '/industrialService',
        childrenArray: [
            {
                code: '',
                name: '高科技产业工程',
                path: site + '/industrialService/hightTech'
            },
            {
                code: '',
                name: '智慧供热',
                path: site + '/industrialService/heatingAndEnvironmentalProtection'
            },
            {
                code: '',
                name: '现代物流',
                path: site + '/industrialService/digitalLogistics'
            },
            {
                code: '',
                name: '其它',
                path: site + '/industrialService/otherBusiness'
            }
        ]
    },
    {
        code: 'practiceResults',
        name: '最佳实践',
        path: '/practiceResults',
        rel:'',
    },
    // {
    //     code: 'ecologicalCooperation',
    //     name: '生态合作',
    //     path: site + '/ecologicalCooperation',
    //     childrenArray: [
    //         {
    //             code: '',
    //             name: '遴选标准',
    //             path: site + "/ecologicalCooperation#standard"
    //         },
    //         {
    //             code: '',
    //             name: '合作伙伴权益',
    //             path: site + "/ecologicalCooperation#rights"
    //         },
    //         {
    //             code: '',
    //             name: '生态伙伴等级',
    //             path: site + "/ecologicalCooperation#grade"
    //         },
    //         {
    //             code: '',
    //             name: '生态类型介绍',
    //             path: site + "/ecologicalCooperation#introduce"
    //         },
    //         {
    //             code: '',
    //             name: '伙伴成长路径',
    //             path: site + "/ecologicalCooperation#path"
    //         },
    //         {
    //             code: '',
    //             name: '成为合作伙伴',
    //             path: site + "/ecologicalCooperation#become"
    //         }
    //     ]
    // },
    {
        code: 'news',
        name: '新闻资讯',
        path: site + '/news',
        childrenArray: [
            {
                code: '',
                name: '集团动态',
                path: 'https://www.cec.com.cn/',
                target: "_blank",
            },
            {
                code: '',
                name: '公司新闻',
                path: site + '/news/company'
            },
            // {
            //     code: '',
            //     name: '市场动态',
            //     path: site + '/news/market'
            // }
        ]
    },
    {
        code: 'partyBuilding',
        name: '党的建设',
        path: site + '/party',
        childrenArray: [
            {
                code: '',
                name: '党的建设',
                path: site + '/party/building'
            },
            {
                code: '',
                name: '致敬百年',
                path: site + '/party/salute'
            },
            {
                code: '',
                name: '党建要闻',
                path: site + '/party/news'
            },
            {
                code: '',
                name: '精彩党团',
                path: site + '/party/wonderful'
            },
            {
                code: '',
                name: '新青年',
                path: site + '/party/youth'
            },
            {
                code: '',
                name: '系统风采',
                path: site + '/party/elegant'
            },
            {
                code: '',
                name: '超常规创新',
                path: site + '/party/innovate'
            }
        ]
    },
    {
        code: 'aboutUs',
        name: '关于我们',
        path: site + '/aboutUs',
        childrenArray: [
            {
                code: '',
                name: '公司简介',
                path: site + '/aboutUs#culture'
            },
            {
                code: '',
                name: '企业文化',
                path: site + '/aboutUs#culture'
            },
            {
                code: '',
                name: '资质认证',
                path: site + '/aboutUs#certification'
            },
            {
                code: '',
                name: '荣誉奖项',
                path: site + '/aboutUs#honro'
            },
            {
                code: '',
                name: '联系我们',
                path: 'https://cecloud.com//about.html'
                // path: site + '/aboutUs/contact'
            }
        ]
    },
    {
        code: 'cninfo',
        name: '投资者关系',
        path: 'http://www.cninfo.com.cn/new/disclosure/stock?orgId=gssz0000032&stockCode=000032#latestAnnouncement',
        rel:'noreferrer',
        target: "_blank",
    },
    {
        code: 'join',
        name: '人才发展',
        path: site + '/aboutUs/join',
    }
]
class Footer extends Component {

    constructor(props) {
        super(props);

        let cArray = categoryArray;
        cArray.forEach(item => {
            item.isOpen = false;
        })

        this.state = {
            categoryArray: cArray,
            openFriend:false
        }
    }

    openFriend(){
        return ()=>{
            this.setState({
                openFriend:!this.state.openFriend
            })
        }
    }

    open(index) {
        let categoryArray = this.state.categoryArray;
        categoryArray[index].isOpen = !categoryArray[index].isOpen;
        this.setState({
            categoryArray:categoryArray
        })
    }

    render() {
        return (
            <div className="footer-wrapper">
                <div className="footer-container">
                    <ul className="footer-nav">
                        {
                            categoryArray.map((item, index) => {
                                let appendClass = (item.isOpen ? ' open' : '');
                                let target = "_self";
                                if(item.target){
                                    target = item.target;
                                }
                                return (
                                    <div key={item.code} className={'footer-nav-item' + appendClass} onClick={() => this.open(index)}>
                                        <div className='nav-item-name'>
                                            <a href={item.path} rel={item.rel} target={target}>{item.name}</a>
                                            {
                                                item.childrenArray && (
                                                    <div className='item-name-arrow'>
                                                        <DownOutlined/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {
                                            item.childrenArray && (
                                                <div className={'nav-item-children'}>
                                                    {
                                                        item.childrenArray.map((children, index) => {
                                                            let target = "_self";
                                                            if(children.target){
                                                                target = children.target;
                                                            }
                                                            return (
                                                                <div key={index} className={'item-children-name'}>
                                                                    <a href={children.path} rel={children.rel} target={target}>{children.name}</a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </ul>
                    <div className="footer-contact">
                        <div className="footer-contact-item">
                            <div className="contact-item-name">
                                联系我们
                            </div>
                            <div className="contact-item-children">
                                <p className="">
                                    地址:
                                </p>
                                <p className="">
                                    深圳市南山区科技园科技路1号 桑达科技大厦15-17层
                                </p>
                                <p className="">
                                    电话:
                                </p>
                                <p className="">
                                    400-080-1100
                                </p>
                                <div className={'friend-link ' + (this.state.openFriend ?'open':'')}>
                                    <div className='name' >友情链接</div>
                                    <div className='icon-wrap'>
                                        <img src='/image/up.svg'/>
                                    </div>
                                    <ul className='children'>
                                        {
                                            friendLink.map((item,index) => {
                                                return (
                                                    <li key={index} className='item'><a href={item.link} target='_blank'>{item.name}</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-contact-item qr-item">
                            <div className="contact-item-name">
                                关注深桑达
                            </div>
                            <div className="contact-item-children">
                                <img className="footer-qrcode" src="/image/qrcode.jpg" alt="qrcode"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="copyright-item">
                        <div>
                            版权所有:
                        </div>
                        <div>
                            深圳市桑达实业股份有限公司
                        </div>
                    </div>
                    <div className="copyright-item">
                        <div>
                            <a target="_blank" href='https://beian.miit.gov.cn/#/Integrated/index'>粤ICP备17078621号</a>
                        </div>
                        {/*<div>*/}
                        {/*    <a target="_blank" href='http://www.beian.gov.cn/portal/registerSystemInfo'>京公网安备11010602004691号</a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
