import './App.less';
import HomeRouter from "./router";
import Header from "./components/Header";
import React,{Suspense} from "react";
import Footer from "./components/Footer";
import Tools from "./components/Tools";

function App() {
    return (
        <Suspense fallback={<></>}>
            {/*<Router basename="/cestc">*/}
            <Header/>
            <HomeRouter/>
            <Footer/>
            <Tools/>
        </Suspense>
    );
}

export default App;
