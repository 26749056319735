import React, {lazy} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'

const Index = lazy(() => import('./pages/index'))

const AboutUsIndex = lazy(() => import('./pages/aboutUs/index'))
const AboutUsContact = lazy(() => import('./pages/aboutUs/contact'))
const AboutUsJoin = lazy(() => import('./pages/aboutUs/join'))

const SolutionIndex = lazy(() => import('./pages/industrialService/index'))
const OtherBusiness = lazy(() => import('./pages/industrialService/other'))
const DigitalHeat = lazy(() => import('./pages/industrialService/heatingAndEnvironmentalProtection'))

const DigitizationIndex = lazy(() => import('./pages/informationService/digitalService/digitization/index'))
const DigitizationBuild = lazy(() => import('./pages/informationService/digitalService/digitization/manufacturing'))
const DigitizationEnergy = lazy(() => import('./pages/informationService/digitalService/digitization/energy'))
const DigitizationMoney = lazy(() => import('./pages/informationService/digitalService/digitization/money'))
const DigitizationScheme = lazy(() => import('./pages/informationService/digitalService/digitization/scheme/index'))
const SmartRailway = lazy(() => import('./pages/informationService/digitalService/gsm-r'))

const EcologicalIndex = lazy(() => import('./pages/ecologicalCooperation'))

const HightTechIndex = lazy(() => import('./pages/industrialService/hightTech/index'))
const hightTechBiomedicine = lazy(() => import('./pages/industrialService/hightTech/biomedicine'))
const HightTechDigitalInformation = lazy(() => import('./pages/industrialService/hightTech/digitalInformation'))
const HightTechNewEnergy = lazy(() => import('./pages/industrialService/hightTech/newEnergy'))
const HightTechNewInfrastructure = lazy(() => import('./pages/industrialService/hightTech/newInfrastructure'))
const HightTechOneBeltOneRoad = lazy(() => import('./pages/industrialService/hightTech/oneBeltOneRoad'))

const DigitalLogistics = lazy(() => import('./pages/industrialService/digitalLogistics'))

const LetterIntegration = lazy(() => import('./pages/industrialService/letterIntegration'))


const ModernDigitalCityIndex = lazy(() => import('./pages/informationService/digitalService/index'))
const ModernDigitalCityCityBrain = lazy(() => import('./pages/informationService/digitalService/cityBrain'))
const ModernDigitalCityCitySafety = lazy(() => import('./pages/informationService/digitalService/citySafety'))
const ModernDigitalCitySmartCommunity = lazy(() => import('./pages/informationService/digitalService/smartCommunity'))
const ModernDigitalCitySmartLighting = lazy(() => import('./pages/informationService/digitalService/smartLighting'))
const ModernDigitalCitySmartSecurity = lazy(() => import('./pages/informationService/digitalService/smartSecurity'))
const ModernDigitalCityDigitalGovernment = lazy(() => import('./pages/informationService/digitalService/digitalGovernment'))

const InformationService = lazy(() => import('./pages/informationService/index'))
const AI = lazy(() => import('./pages/informationService/AI'))
const DataGovernanceAndOperations = lazy(() => import('./pages/informationService/dataGovernanceAndOperations'))
const BigDataProductSystem = lazy(() => import('./pages/informationService/dataGovernanceAndOperations/bigDataProductSystem'))

const DigitalConsulting = lazy(() => import('./pages/informationService/digitalConsulting'))

const Case = lazy(() => import('./pages/case'))

const PracticeResults = lazy(()=> import('./pages/practiceResults'))

const PartBuildingIndex = lazy(() => import('./pages/party/index'))
const DefaultInfo = lazy(() => import('./pages/info/default'))

const News = lazy(() => import('./pages/news/index'))
const NewsList = lazy(() => import('./pages/news/list'))
const NewsInfo = lazy(() => import('./pages/news/info'))


export default class HomeRouter extends React.Component {
    render() {
        return (

            <Router>
                <Switch>
                    {/*产业服务*/}
                    <Route path="/industrialService" exact component={SolutionIndex}/>
                    {/*高科技工程产业*/}
                    <Route path="/industrialService/hightTech" exact component={HightTechIndex}/>
                    <Route path="/industrialService/hightTech/biomedicine" exact component={hightTechBiomedicine}/>
                    <Route path="/industrialService/hightTech/digitalInformation" exact
                           component={HightTechDigitalInformation}/>
                    <Route path="/industrialService/hightTech/newEnergy" exact component={HightTechNewEnergy}/>
                    <Route path="/industrialService/hightTech/newInfrastructure" exact
                           component={HightTechNewInfrastructure}/>
                    <Route path="/industrialService/hightTech/oneBeltOneRoad" exact
                           component={HightTechOneBeltOneRoad}/>
                    <Route path="/industrialService/hightTech/:code/:id" exact component={DefaultInfo}/>
                    {/*供热环保*/}
                    <Route path="/industrialService/heatingAndEnvironmentalProtection" exact component={DigitalHeat}/>
                    {/*现代物流*/}
                    <Route path="/industrialService/digitalLogistics" exact component={DigitalLogistics}/>
                    <Route path="/industrialService/digitalLogistics/:code" exact component={DigitalLogistics}/>
                    <Route path="/industrialService/digitalLogistics/:code/:id" exact component={DefaultInfo}/>
                    {/*其它*/}
                    <Route path="/industrialService/otherBusiness" exact component={OtherBusiness}/>

                    {/*信息服务*/}
                    <Route path="/informationService" exact component={InformationService}/>

                    {/*数字服务*/}
                    <Route path="/informationService/digitalService" exact component={ModernDigitalCityIndex}/>
                    {/*信创数字大脑*/}
                    <Route path="/informationService/digitalService/cityBrain" exact
                           component={ModernDigitalCityCityBrain}/>
                    {/*信创服务业务*/}
                    <Route path="/informationService/digitalService/cityBrain/letterIntegration" exact component={LetterIntegration}/>

                    {/*数据治理与运营*/}
                    <Route path="/informationService/dataGovernanceAndOperations" exact
                           component={DataGovernanceAndOperations}/>
                    {/*<Route path="/informationService/digitalService/dataGovernanceAndOperations/bigDataProductSystem"*/}
                    {/*       exact component={BigDataProductSystem}/>*/}
                    <Route path="/informationService/dataGovernanceAndOperations/:code" exact
                           component={DefaultInfo}/>
                    {/*行业数字化*/}
                    <Route path="/informationService/digitalService/digitization" exact component={DigitizationIndex}/>
                    <Route path="/informationService/digitalService/digitization/manufacturing" exact
                           component={DigitizationBuild}/>
                    <Route path="/informationService/digitalService/digitization/energy" exact
                           component={DigitizationEnergy}/>
                    <Route path="/informationService/digitalService/digitization/financial" exact
                           component={DigitizationMoney}/>
                    <Route path="/informationService/digitalService/digitization/scheme" exact
                           component={DigitizationScheme}/>

                    <Route path="/informationService/digitalService/digitization/digitalFactory" exact component={lazy(() => import('./pages/informationService/digitalService/digitization/digitalFactory'))}/>

                    <Route path="/informationService/digitalService/digitization/:parentCode/:code" exact
                           component={DefaultInfo}/>
                    {/*智慧铁路*/}
                    <Route path="/informationService/digitalService/cityBrain/smartRailway" exact component={SmartRailway}/>
                    <Route path="/informationService/digitalService/cityBrain/smartRailway/:code/:id" exact
                           component={DefaultInfo}/>
                    {/*智慧照明*/}
                    <Route path="/informationService/digitalService/cityBrain/smartLighting" exact
                           component={ModernDigitalCitySmartLighting}/>
                    <Route path="/informationService/digitalService/cityBrain/smartLighting/:code" exact component={Case}/>
                    <Route path="/informationService/digitalService/cityBrain/smartLighting/:parentCode/:id" exact
                           component={DefaultInfo}/>
                    {/*<Route path="/informationService/digitalService/cityBrain/citySafety" exact component={ModernDigitalCityCitySafety}/>*/}
                    {/*<Route path="/informationService/digitalService/smartCommunity" exact component={ModernDigitalCitySmartCommunity}/>*/}
                    <Route path="/informationService/digitalService/cityBrain/smartSecurity" exact component={ModernDigitalCitySmartSecurity}/>
                    {/*<Route path="/informationService/digitalService/digitalGovernment" exact component={ModernDigitalCityDigitalGovernment}/>*/}

                    {/*数字化咨询*/}
                    <Route path="/informationService/digitalService/cityBrain/digitalConsulting" exact component={DigitalConsulting}/>

                    <Route path="/informationService/digitalService/cityBrain/:code" exact component={DefaultInfo}/>



                    {/*实践成果*/}
                    <Route path="/informationService/digitalService/:code" exact component={Case}/>
                    <Route path="/informationService/digitalService/:parentCode/:id" exact component={DefaultInfo}/>


                    <Route path="/informationService/dataInnovation" exact component={lazy(() => import('./pages/informationService/dataInnovation'))}/>
                    <Route path="/informationService/dataInnovation/dataSecurity" exact component={lazy(() => import('./pages/informationService/dataInnovation/dataSecurity'))}/>
                    <Route path="/informationService/dataInnovation/dataCenterstation" exact component={lazy(() => import('./pages/informationService/dataInnovation/datacenterstation/index'))}/>
                    <Route path="/informationService/dataInnovation/dataCenterstation/:code" exact component={lazy(() => import('./pages/informationService/dataInnovation/datacenterstation/info'))}/>

                    <Route path="/informationService/dataInnovation/dataIntelligence" exact component={lazy(() => import('./pages/informationService/dataInnovation/dataintelligence'))}/>
                    <Route path="/informationService/dataInnovation/dataIntelligence/:code" exact component={DefaultInfo}/>

                    {/*<Route path="/informationService/AI" exact component={AI}/>*/}
                    {/*<Route path="/informationService/:parentCode/:code" exact component={DefaultInfo}/>*/}

                    <Route path="/practiceResults" exact component={PracticeResults}/>
                    <Route path="/practiceResults/:code" exact component={PracticeResults}/>
                    <Route path="/practiceResults/:code/:id" exact component={DefaultInfo}/>

                    <Route path="/party" exact component={PartBuildingIndex}/>
                    <Route path="/party/:code" exact component={PartBuildingIndex}/>
                    <Route path="/party/:code/:id" component={DefaultInfo}/>

                    <Route path="/news" exact component={News}/>
                    <Route path="/news/:code.:page" exact component={NewsList}/>
                    <Route path="/news/:code" exact component={NewsList}/>
                    <Route path="/news/:code/:id" exact component={NewsInfo}/>

                    <Route path="/aboutUs" exact component={AboutUsIndex}/>
                    <Route path="/aboutUs/join" exact component={AboutUsJoin}/>
                    <Route path="/aboutUs/contact" exact component={AboutUsContact}/>

                    {/*<Route path="/ecologicalCooperation" exact component={EcologicalIndex}/>*/}

                    <Route path="/" component={Index}/>
                </Switch>
            </Router>

        );
    }
}
