import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.NODE_ENV === 'development') {
    // const { initMock } = require('./mock')
    // initMock()
}

if (process.env.NODE_ENV === 'production') {
    //
}

ReactDOM.render( 
    <App />,
  document.getElementById('root')
);
