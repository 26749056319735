import React, {Component} from 'react';
import './style.less'
class Tools extends Component {
    render() {
        return (
            <div className="global-tools">
                <div className="wrapper">
                    <div className="icon-wrapper">
                        <img src="/image/icon/tools.svg"/>
                    </div>
                    <div className="name">
                        联系我们
                    </div>
                </div>
                <div className="layer">
                    <div className="item-wrapper">
                        <a target="_blank" href='https://cecloud.com/contact.html'>
                            <div className="item">
                                <div className="icon-wrapper">
                                    <img src="/image/icon/tools-tel.png" width="100%"/>
                                </div>
                                <div className="content">
                                    <div className="name">
                                        联系我们
                                    </div>
                                    <div className="description">
                                        400-080-1100
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href='http://webchat.cestc.cn:8012/#/webchat?screen=web&channel=webchat&accesses_id=1&skill=QW023&type=2'>
                            <div className="item">
                                <div className="icon-wrapper">
                                    <img src="/image/icon/tools-online.svg" width="100%"/>
                                </div>
                                <div className="content">
                                    <div className="name">
                                        在线客服
                                    </div>
                                    <div className="description">
                                        为你提供专业的咨询服务，快速解决您的问题
                                    </div>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        );
    }
}

export default Tools;
