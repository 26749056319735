import React, {Component} from 'react';
import './style.less'
import DownOutlined from "../Icon/DownOutlined";

const site = process.env.PUBLIC_URL;
const categoryArray = [
    {
        code: 'technicalAndService',
        name: '数字与信息服务',
        path: site + '/informationService',
        childrenArray: [
            {
                code: '',
                name: '数字基础设施',
                path: 'https://cecloud.com',
                target: "_blank",
            },
            {
                code: '',
                name: '数据创新',
                path: site + '/informationService/dataInnovation'
            },
            // {
            //     code: '',
            //     name: '数据服务',
            //     path: site + '/informationService/dataGovernanceAndOperations'
            // },
            {
                code: '',
                name: '数字政府与行业数字化',
                path: site + '/informationService/digitalService/cityBrain'
            },

            // {
            //     code: '',
            //     name: 'DigitalConsulting',
            //     path: site + '/informationService/digitalConsulting'
            // }
        ],
    },
    {
        code: 'industrialService',
        name: '产业服务',
        path: site + '/industrialService',
        childrenArray: [
            {
                code: '',
                name: '高科技产业工程',
                path: site + '/industrialService/hightTech'
            },
            {
                code: '',
                name: '智慧供热',
                path: site + '/industrialService/heatingAndEnvironmentalProtection'
            },
            {
                code: '',
                name: '现代物流',
                path: site + '/industrialService/digitalLogistics'
            },
            {
                code: '',
                name: '其它',
                path: site + '/industrialService/otherBusiness'
            }
        ]
    },
    {
        code: 'practiceResults',
        name: '最佳实践',
        path: '/practiceResults',
        rel:'',
    },
    // {
    //     code: 'ecologicalCooperation',
    //     name: '生态合作',
    //     path: site + '/ecologicalCooperation',
    //     childrenArray: [
    //         {
    //             code: '',
    //             name: '遴选标准',
    //             path: site + "/ecologicalCooperation#standard"
    //         },
    //         {
    //             code: '',
    //             name: '合作伙伴权益',
    //             path: site + "/ecologicalCooperation#rights"
    //         },
    //         {
    //             code: '',
    //             name: '生态伙伴等级',
    //             path: site + "/ecologicalCooperation#grade"
    //         },
    //         {
    //             code: '',
    //             name: '生态类型介绍',
    //             path: site + "/ecologicalCooperation#introduce"
    //         },
    //         {
    //             code: '',
    //             name: '伙伴成长路径',
    //             path: site + "/ecologicalCooperation#path"
    //         },
    //         {
    //             code: '',
    //             name: '成为合作伙伴',
    //             path: site + "/ecologicalCooperation#become"
    //         }
    //     ]
    // },
    {
        code: 'news',
        name: '新闻资讯',
        path: site + '/news',
        childrenArray: [
            {
                code: '',
                name: '集团动态',
                path: 'https://www.cec.com.cn/',
                target: "_blank",
            },
            {
                code: '',
                name: '公司新闻',
                path: site + '/news/company'
            },
            // {
            //     code: '',
            //     name: '市场动态',
            //     path: site + '/news/market'
            // }
        ]
    },
    {
        code: 'partyBuilding',
        name: '党的建设',
        path: site + '/party',
        childrenArray: [
            {
                code: '',
                name: '党的建设',
                path: site + '/party/building'
            },
            {
                code: '',
                name: '致敬百年',
                path: site + '/party/salute'
            },
            {
                code: '',
                name: '党建要闻',
                path: site + '/party/news'
            },
            {
                code: '',
                name: '精彩党团',
                path: site + '/party/wonderful'
            },
            {
                code: '',
                name: '新青年',
                path: site + '/party/youth'
            },
            {
                code: '',
                name: '系统风采',
                path: site + '/party/elegant'
            },
            {
                code: '',
                name: '超常规创新',
                path: site + '/party/innovate'
            }
        ]
    },
    {
        code: 'aboutUs',
        name: '关于我们',
        path: site + '/aboutUs',
        childrenArray: [
            {
                code: '',
                name: '公司简介',
                path: site + '/aboutUs#culture'
            },
            {
                code: '',
                name: '企业文化',
                path: site + '/aboutUs#culture'
            },
            {
                code: '',
                name: '资质认证',
                path: site + '/aboutUs#certification'
            },
            {
                code: '',
                name: '荣誉奖项',
                path: site + '/aboutUs#honro'
            },
            {
                code: '',
                name: '联系我们',
                path: 'https://cecloud.com//about.html'
                // path: site + '/aboutUs/contact'
            }
        ]
    },
    {
        code: 'cninfo',
        name: '投资者关系',
        path: 'http://www.cninfo.com.cn/new/disclosure/stock?orgId=gssz0000032&stockCode=000032#latestAnnouncement',
        rel:'noreferrer',
        target: "_blank",
    },
    {
        code: 'join',
        name: '人才发展',
        path: site + '/aboutUs/join',
    }
]


class Header extends Component {

    constructor(props) {
        super(props);

        let cArray = categoryArray;
        cArray.forEach(item => {
            item.isOpen = false;
        })

        this.state = {
            categoryArray: cArray,
            menuOpen:false,
        }
    }

    getActiveMenuIndex() {
        var pagePath = window.location.pathname
        var pageActiveList = [
            'informationService',
            'industrialService',
            'practiceResults',
            'news',
            'partyBuilding',
            'aboutUs'
        ]

        return this.findMenuIndex(pageActiveList, pagePath)
    }

    findMenuIndex(arr, str) {
        var res = -1

        for (var i = 0; i < arr.length; i++) {
            if (str.indexOf(arr[i]) > -1) {
                res = i
            }
        }
        return res
    }

    open(index) {
        let categoryArray = this.state.categoryArray;
        categoryArray[index].isOpen = !categoryArray[index].isOpen;
        this.setState({
            categoryArray:categoryArray
        })
    }

    openMenu(){
        this.setState({
            menuOpen:true
        })
    }

    closeMenu(){
        this.setState({
            menuOpen:false
        })
    }

    render() {

        const activeIndex = this.getActiveMenuIndex();
        const categoryArray = this.state.categoryArray;
        const menuOpen = this.state.menuOpen;

        return (

            <div className={'header-wrapper' + (menuOpen ? ' open':'')}>
                <div className={'header-container'}>
                    <a href={site || '/'} className={'header-logo'}>
                        {/*<img src={'/image/logo-100.png'} style={{marginRight: '25px'}}/>*/}
                        <img className="pc-logo" src={'/image/logo.svg'}/>
                        <img className="mobile-logo" src={'/image/logo_white.svg'}/>
                    </a>
                    <div className={'header-nav'}>
                        <ul className={'header-nav-wrapper'}>
                            {
                                categoryArray.map((item, index) => {
                                    let appendClass = (index === activeIndex ? ' active' : '') + (item.isOpen ? ' open' : '')
                                    let target = "_self";
                                    if(item.target){
                                        target = item.target;
                                    }

                                    return (
                                        <li key={item.code} className={'header-nav-item' + appendClass} onClick={() => this.open(index)}>
                                            <div className={'header-nav-item-name'}>
                                                <a href={item.path} rel={item.rel} target={target}>{item.name}</a>
                                                {
                                                    item.childrenArray && ( <div className='header-nav-item-name-arrow'><DownOutlined /></div> )
                                                }

                                            </div>
                                            {
                                                item.childrenArray && (
                                                    <div className={'header-nav-item-children-wrapper'}>
                                                        <ul>
                                                            {
                                                                item.childrenArray.map((children, index) => {

                                                                    let target = "_self";
                                                                    if(children.target){
                                                                        target = children.target;
                                                                    }

                                                                    return (
                                                                        <li key={index} className={'header-nav-item-children-item-name'}>
                                                                            <a href={children.path} rel={children.rel} target={target}>{children.name}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {/*<div className={'header-tools'}>*/}
                    {/*    <ul className={'header-tools-wrapper'}>*/}
                    {/*        <li className={'header-tools-item'}>*/}
                    {/*            <a target={'_blank'} href={'http://www.cninfo.com.cn/new/disclosure/stock?orgId=gssz0000032&stockCode=000032#latestAnnouncement'}>投资者关系</a>*/}
                    {/*        </li>*/}
                    {/*        <li className={'header-tools-item'}>*/}
                    {/*            <a target={'_blank'} href={site + '/aboutUs/join'}>加入我们</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className='header-menu'>
                        <img onClick={() => this.openMenu()} className="header-menu-item" name='open' src="/common/image/menu.svg"/>
                        <img onClick={() => this.closeMenu()} className='header-menu-item' name='close' src="/common/image/close.svg"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
